
import { Component, Prop, Vue } from "vue-property-decorator";

import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { FilePicker } from "@capawesome/capacitor-file-picker";

interface Upload {
  id: number;
  url: string;
  extension: string;
  original_name: string;
  name: string;
  path: string;
  data: string;
}

@Component
export default class MyUploadsComponent extends Vue {
  @Prop(Number) readonly category!: number;

  mounted() {
    this.fetchDir();
  }

  shared = false;
  categoryName = "";
  deleteDialog = false;
  uploads: Upload[] = [];

  get deviceInfo() {
    return this.$store.getters["device/deviceInfo"];
  }

  get buildingComplexData() {
    return this.$store.getters["app/buildingComplexData"];
  }

  get managementCompany() {
    return this.buildingComplexData?.management_company;
  }

  fetchDir() {
    this.$store.dispatch("documents/dir", this.category).then(response => {
      this.categoryName = response.data.meta.name;
      this.shared = response.data.meta.is_shared;
      this.uploads = response.data.data;
    });
  }

  share(value: boolean) {
    const body = {
      id: this.category,
      data: { share: value }
    };

    this.$store.dispatch("documents/share", body);
  }

  async pickPhoto(camera: boolean) {
    const photo = await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      resultType: CameraResultType.Uri,
      source: camera ? CameraSource.Camera : CameraSource.Photos
    });

    if (photo.webPath) {
      const formData = new FormData();
      const getTime = new Date().getTime();

      const blob = await fetch(photo.webPath).then(r => r.blob());
      const file = new File([blob], `photo_${getTime}.${photo.format}`);

      formData.append("file", file);

      const response = await this.$store.dispatch("documents/upload", formData);

      await this.$store.dispatch("documents/attach", {
        id: this.category,
        data: { upload_id: response.data.id }
      });

      this.fetchDir();
    }
  }

  async pickFile() {
    const result = await FilePicker.pickFiles({
      types: ["application/pdf"],
      readData: true
    });

    if (result.files.length == 1) {
      const formData = new FormData();
      const pickedFile = result.files[0];

      if (pickedFile.blob) {
        formData.append("file", pickedFile.blob, pickedFile.name);
      }

      if (pickedFile.data) {
        // TODO: refactoring https://github.com/capawesome-team/capacitor-plugins/issues/58
        const blob = this._base64ToBlob(pickedFile.data, pickedFile.mimeType);

        formData.append("file", blob, pickedFile.name);
      }

      const response = await this.$store.dispatch("documents/upload", formData);

      await this.$store.dispatch("documents/attach", {
        id: this.category,
        data: { upload_id: response.data.id }
      });

      this.fetchDir();
    }
  }

  showDeleteDialog() {
    this.deleteDialog = true;
  }

  async remove(upload: Upload) {
    await this.$store.dispatch("documents/delete", {
      document: upload.id
    });

    this.deleteDialog = false;
    this.fetchDir();
  }

  documentIcon(extension: string) {
    let icon = "mdi-file-outline";

    if (extension.match(/jpg|jpeg|png/g) != null) icon = "mdi-file-image-outline";
    if (extension.match(/pdf/g) != null) icon = "mdi-file-pdf-box";

    return icon;
  }

  _base64ToBlob(base64: string, mimeType: string) {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    return new Blob([byteArray], { type: mimeType });
  }
}
